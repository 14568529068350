import accordion, { AccordionGroup } from '@components/accordion/accordion.init';
import blog from '@components/blog/blog.init';
import button from '@blocks/button/button.init';
import carousel from '@components/carousel/carousel.init';
import ctaContact from '@components/cta-contact/cta-contact.init';
import expandable from '@components/expandable/expandable.init';
import globalFooter from '@components/global-footer/global-footer.init';
import globalHeader from '@components/global-header/global-header.init';
import image from '@blocks/image/image.init';
import marqueeMajor from '@components/marquee-major/marquee-major.init';
import marqueeTwoColumn from '@components/marquee-two-column/marquee-two-column.init';
import modal from '@components/modal/modal.init';
import parallaxGraphic from '@components/parallax-graphic/parallax-graphic.init';
import table from '@blocks/table/table.init';
import tabs from '@components/tabs/tabs.init';

import domReady from './lib/domReady';
import initModule from './lib/initModule';
import addIeBodyClass from './lib/detectIE11';
import MarqueeTwoColumn from "../ui/components/marquee-two-column/marquee-two-column.init";
// import loadSvg from './lib/load-svg';

// const svgSpritePath = `${window.location.protocol}//${window.location.host}/bp-sprite.svg`;

// Intialize scripts here requiring DOM access.
//
// Any modules imported here should export a function
// that takes a node as its only parameter.
// Import the module then initialize it below inside
// domScripts(), calling initModule() and passing in
// the function and a selector that aligns with the element
// you want to pass into the module.
// initModule() calls the method on each instance
// of the selector passed, so your script can assume
// it is working on a unique DOM node.
//
// example:
// import coolThing from '../ui/blocks/CoolThing/cool-thing.init';
// initModule(coolThing, '.cool-thing');

const domScripts = () => {
  initModule(accordion, '.accordion');
  initModule(AccordionGroup, '.accordion__group');
  initModule(addIeBodyClass, 'body');
  initModule(blog, '.blog');
  initModule(button, '.button--burger');
  initModule(carousel, '.carousel');
  initModule(ctaContact, '.cta-contact');
  initModule(expandable, '.expandable');
  initModule(globalFooter, '.global-footer');
  initModule(globalHeader, '.global-header');
  initModule(image, '.image__lazy-wrapper');
  initModule(marqueeMajor, '.marquee-major');
  initModule(marqueeTwoColumn, '.marquee-two-column');
  initModule(parallaxGraphic, '.parallax-graphic');
  initModule(modal, '.modal');
  initModule(table, '.richtext table');
  initModule(tabs, '.tabs');
//  loadSvg(svgSpritePath);
};

// domReady ensures our scripts fire inside Storybook
// even when navigating component to component,
// calling the passed function on DOMContentLoaded
// and each time the page changes, using MutationObserver
domReady(domScripts);
