// DOM-specific JS should be in this file in the same
// `export default()` style as in the commented line below

// uncomment line 7 below and the line importing this file into
//  marquee-two-column.js (line 5) to run your script in the browser

// export default(() => console.log('hello from MarqueeTwoColumn!'));

import select from 'dom-select';
import axios from 'axios';

export const MarqueeTwoColumn = (el) => {
  const ui = {
    el,
    sendBtn: select('.marquee-two-column__send', el),
    form: select('#newsletter-form', el),
    feedbackSummary: select('#feedback-summary', el),
    successPanel: select('#marquee-two-column__form-success', el),
    firstName: select('#firstName', el),
    lastName: select('#lastName', el),
    email: select('#email', el),
  };

  const addEvents = () => {

    ui.sendBtn.addEventListener('click', (event) => {
      event.preventDefault();
      // trigger the browser validation feedback mechanism
      ui.form.reportValidity();

      // if form is not valid, bail - don't submit it.
      if (!ui.form.checkValidity()) {
        return;
      }

      axios({
        method: "post",
        url: "/newsletter/things",
        data: {
          firstName: ui.firstName.value,
          lastName: ui.lastName.value,
          email: ui.email.value
        },
        cache: false,
        dataType: "json",
        contentType: "application/json; charset=utf-8"
      })
        .then(response => {
          console.log(response.data);

          // Inherited php functionality seems to always provide 200, "OK" status
          // parse it for actual success messaging
          if (response.data === "success") {
            ui.form.classList.add('hidden');
            ui.successPanel.classList.remove('hidden');
          } else {
            ui.form.classList.add('hidden');
            ui.successPanel.classList.remove('hidden');
          }


        })
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          }
          ui.feedbackSummary.classList.remove('hidden');
        });

    });
  };

  const init = () => {
    addEvents();
  };

  init();
};

export default MarqueeTwoColumn;
