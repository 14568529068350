// DOM-specific JS should be in this file in the same
// `export default()` style as in the commented line below

// uncomment line 7 below and the line importing this file into
//  cta-contact.js (line 5) to run your script in the browser

// export default(() => console.log('hello from CtaContact!'));

import select from 'dom-select';
import axios from 'axios';

export const CtaContact = (el) => {
  const ui = {
    el,
    toggleBtn: select('.cta-contact__toggle', el),
    sendBtn: select('.cta-contact__send', el),
    form: select('#contact-form', el),
  };

  const addEvents = () => {
    ui.toggleBtn.addEventListener('click', () => {
      ui.el.classList.remove('step1', 'step3')
      ui.el.classList.add('step2');
    });

    ui.sendBtn.addEventListener('click', (event) => {
      event.preventDefault();
      // trigger the browser validation feedback mechanism
      ui.form.reportValidity();
      // add class flag to trigger pseudo-class based style feedback
      ui.form.classList.add('show-validation');

      axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
      axios.post(ui.form.action, new FormData(ui.form))
        .then(response => {
          console.log(response.data)
          ui.el.classList.remove('step1', 'step2');
          ui.el.classList.add('step3');
        })
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          }
        });

    });
  };

  const init = () => {
    addEvents();
  };

  init();
};

export default CtaContact;
