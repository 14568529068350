import Flickity from 'flickity';
import select from 'dom-select';
import throttle from 'lodash.throttle';

export default (el) => {
  const ui = {
    el,
    container: select('.carousel__container', el),
    cells: select.all('.carousel__cell', el),
    prev: select('#carousel-prev', el),
    next: select('#carousel-next', el),
    index: select('span#index')
  };

  const flkty = new Flickity(ui.container, {
    adaptiveHeight: true,
    autoPlay: 0,
    draggable: true,
    imagesLoaded: true,
    pageDots: false,
    wrapAround: true,
    prevNextButtons: false,
    lazyLoad: true,
    selectedAttraction: .15,
    friction: 0.8,
    setGallerySize: true,
  });

  const setColor = function () {
    let currentPanel = flkty.selectedElement;
    ui.el.style.color = currentPanel.dataset.color;
    ui.el.style.background = currentPanel.dataset.bg;
  }

  const resetSize = function () {
    throttle(() => { 
      flkty.resize(); 
    },  
      250, { 
        trailing: true, 
        leading: true 
      }
    )
  }

  // set the background-color on the carousel onload
  setColor();

  // counter
  flkty.on('change', (index) => {
    const currentPanel = (index + 1);
    ui.index.innerHTML = (currentPanel);
  });

  // color setter 
  flkty.on('select', (index) => {
    setColor();
  });

  ui.next.addEventListener('click', () => {
    flkty.next();
  });

  ui.prev.addEventListener('click', () => {
    flkty.previous();
  });

  // resize carousel cells on (throttled) window.resize
  window.addEventListener('resize', (e) => {
    resetSize();
  });
};
