
import select from 'dom-select';

export const GlobalHeader = (el) => {
  const ui = {
    el,
    body: select('body'),
    pageshell: select('.pageshell'),
  };

  let lastScrollTop = 0;

  const addEvents = () => {
    window.addEventListener("scroll", function(){ 
      let st = window.pageYOffset || document.documentElement.scrollTop; 
      if (st > lastScrollTop){
        // down scroll code
        if (st > 50) {
          ui.pageshell.classList.add('scrolled-down');
          ui.pageshell.classList.remove('scrolled-up');
        }
      } else {
         // up scroll code
        ui.pageshell.classList.add('scrolled-up');
        ui.pageshell.classList.remove('scrolled-down');

        if (st === 0) {
          ui.pageshell.classList.remove('scrolled-down');
          ui.pageshell.classList.remove('scrolled-up');
        }
      }
      // For Mobile or negative scrolling
      lastScrollTop = st <= 0 ? 0 : st;
    }, false);
  };

  const init = () => {
    addEvents();
  };

  init();
};

export default GlobalHeader;
