import select from 'dom-select';

export const MarqueeMajor = (el) => {
  const ui = {
    el,
    images: select('.marquee-major__images', el),
    tagline: select('.marquee-major__tagline', el),
  };

  let lastScrollTop = 0;
  let threshold = 420; /* maximum scroll amount */
  let increment = ( threshold / 6); /* maximum scroll amount divided by number of pics */
  
  // console.log (increment);
  // console.log(ui.el.offsetWidth);

  const resizing = function () {
    let viewportWidth = window.innerWidth;
    if (viewportWidth >= 768) {
      ui.images.style.transform = 'translateY(0px)';
      ui.tagline.style.transform = 'translateY(0px)';
    }
  }
  
  const addEvents = () => {
    window.addEventListener("scroll", function(){
      let st = window.pageYOffset || document.documentElement.scrollTop; 

      if (st <= (threshold)) {
        if (ui.el.offsetWidth > '767') {
          ui.images.style.transform = 'translateY(' + st + 'px)';
          ui.tagline.style.transform = 'translateY(-' + (st / 6) + 'px)';
        }
      }
      if (st < (increment)) {
        ui.images.setAttribute('data-step', 'step-1');
      }
      if (st > (increment) && st < (increment * 2)) {
        ui.images.setAttribute('data-step', 'step-2');
      }
      if (st > (increment * 2) && st < (increment * 3)) {
        ui.images.setAttribute('data-step', 'step-2');
      }
      if (st > (increment * 3) && st < (increment * 4)) {
        ui.images.setAttribute('data-step', 'step-3');
      }
      if (st > (increment * 4) && st < (increment * 5)) {
        ui.images.setAttribute('data-step', 'step-4');
      }
      if (st > (increment * 5) && st < (increment * 6)) {
        ui.images.setAttribute('data-step', 'step-5');
      }
      if (st > (increment * 6) && st < (increment * 7)) {
        ui.images.setAttribute('data-step', 'step-6');
      }

      // For Mobile or negative scrolling
      lastScrollTop = st <= 0 ? 0 : st;
      
    }, false);

    window.addEventListener( 'resize', () => {
      resizing();
    })
  };

  const init = () => {
    addEvents();
  };

  init();
};

export default MarqueeMajor;
