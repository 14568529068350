import select from 'dom-select';
import ScrollMagic from 'scrollmagic';

export const ParallaxGraphic = (el) => {
  const ui = {
    el,
    image: select('.parallax-graphic__img', el),
  };

  const controller = new ScrollMagic.Controller();
  let viewportWidth = window.innerWidth;

  const addEvents = () => {
    const scene = new ScrollMagic.Scene({
      triggerElement: "#trigger1", duration: '900' })
        .setPin("#pin1")
        //.offset(300)
        .addTo(controller);

    window.addEventListener( 'resize', () => {
      scene.removePin(true);
      scene.setPin(ui.image);
      scene.refresh();
    })
  };

  const init = () => {
    addEvents();
  };

  init();
};

export default ParallaxGraphic;
