import select from 'dom-select';

export const Burger = (el) => {
  const ui = {
    el,
    globalHeader: select('.global-header'),
    body: select('body'),
  };

  const addEvents = () => {
    ui.el.addEventListener('click', () => {
      ui.el.classList.toggle('active');
      try {
        ui.globalHeader.classList.toggle('nav-open');
        ui.body.classList.toggle('scroll-lock');
      } catch (error) {
        // console.log(error);
      }
    });
  };

  const init = () => {
    addEvents();
  };

  init();
};

export default Burger;
