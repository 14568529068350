import select from 'dom-select';

export const GlobalFooter = (el) => {
  const ui = {
    el,
    pageshellMain: select('.pageshell__main'),
  };

  const addPadding = function () {
    let viewportWidth = window.innerWidth;
    let footerHeight = ui.el.offsetHeight + 'px';
    if (viewportWidth >= 768) {
      ui.pageshellMain.style.marginBottom = footerHeight;
    } else {
      ui.pageshellMain.style.marginBottom = 0;
    }
  }

  const addEvents = () => {
    addPadding();

    window.addEventListener( 'resize', () => {
      addPadding();
    })
  };

  const init = () => {
    addEvents();
  };

  init();
};

export default GlobalFooter;
