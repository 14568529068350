import select from 'dom-select';
import ScrollMagic from 'scrollmagic';

export const Blog = (el) => {
  const ui = {
    el,
    latestWork: select('.latest-work'),
    body: select('body'),
    globalFooter: select('.global-footer'),
  };

  const slide = ui.el;

  const controller = new ScrollMagic.Controller(
    {
			globalSceneOptions: {
				// triggerHook: 'onLeave',
        offset: 0,
			}
		}
  );

  const addEvents = () => {
    /* toggle class
    new ScrollMagic.Scene({
      triggerElement: slide,
      triggerHook: 1, // toggle class when scrolled into viewport by 1px
      offset: '-1000' // move trigger to center of element
    })
    .setClassToggle(".blog", "in-view")
    .addTo(controller);
     */
  };

  const init = () => {
    addEvents();
  };

  init();
};

export default Blog;
